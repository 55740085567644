.forumHero {
    padding: 4% 6%;
    background-color: #001c89;
    min-height: 433px;
    color: #ffffff;
}

.frum_content_container {
    max-width: 1000px;
    width: 98%;
    margin: 0 auto;
}

.c_a_t_btn {
    font-weight: 300;
    justify-content: center;
    gap: 5px;
    border: none;
    background-color: #ffffff;
    color: #001c89;
    align-items: center;
    padding: 16px 32px;
}

.create_thread {
    position: relative;
    padding-top: 100px;
}

.ratings_group {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1rem;
}

.rating {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.rating span {
    margin-right: 0.5rem;
}

.star_group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    text-align: center;
}

.star {
    cursor: pointer;
    color: #ccc;
    text-align: center;
    font-size: 24px;
}

.star.active {
    color: yellow;
}

.star_box {
    display: block;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 20px;

    p {
        font-size: 8px;
    }
}

.community_cards {
    display: flex;
    justify-content: space-around;
    margin: 20px;
    gap: 20px;
    padding: 2%;
}

.card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.card_left {
    border-left: 5px solid green;
}

.card_right {
    border-left: 5px solid orange;
}

.card_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.card_text {
    font-size: 18px;
    color: #555;
}

.card_icon {
    font-size: 18px;
    color: #555;
}

.card:hover {
    transform: scale(1.05);
}
