@import "/src/assets/css/variables.scss";

.write_review {
  position: relative;
  padding-top: 150px;
  max-width: 700px;
  margin: 0 auto;

  h2 {
    color: #001c89;
    font-weight: 600;
    font-size: 32px;
    text-align: center;
  }

  h3 {
    font-size: 26px;
    color: #282828;
    font-weight: 500;
  }
}

.ratings_group {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
}

.rating {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.rating span {
  margin-right: 0.5rem;
}

.star_group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  text-align: center;
}

.star_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 20px;

  .star {
    cursor: pointer;
    color: #ccc;
    text-align: center;
    font-size: 24px;
  }

  .active {
    color: #ffa800;
    border: none;
    text-align: center;
    font-size: 24px;
  }

  p {
    font-size: 8px;
  }
}

.form_group {
  margin-bottom: 20px;
}

.form_group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form_group .review_input,
.form_group select,
.form_group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
}

.form_group input:focus,
.form_group select:focus,
.form_group textarea:focus {
  border-color: #007bff;
}

.form_group input[type="file"] {
  border: none;
}

.text_area {
  height: 300px;
  border-radius: 16px;
}

.image_preview_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;

  .image_preview {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 0.25rem;
    overflow: hidden;
    border: 1px solid #d1d1d1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 660px) {
  .rating_label {
    font-size: 14px;
  }
  .star_box {
    display: flex;
    border: none;
    padding: 6px;

    .star {
      cursor: pointer;
      color: #ccc;
      text-align: center;
      font-size: 24px;
    }

    .active {
      color: #ffa800;
      border: none;
      text-align: center;
      font-size: 24px;
    }

    p {
      font-size: 8px;
    }
  }
}

@media (max-width: 500px) {
  .rating_label {
    font-size: 12px;
  }
  .star_box {
    display: flex;
    border: none;
    padding: 3px;

    .star {
      cursor: pointer;
      color: #ccc;
      text-align: center;
      font-size: 24px;
    }

    .active {
      color: #ffa800;
      border: none;
      text-align: center;
      font-size: 24px;
    }

    p {
      display: none;
    }
  }
}
