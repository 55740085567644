.companyHeroText {
    width: 800px;
    margin-top: 100px;
    margin-left: 250px;
    margin-bottom: 100px;
    gap: 24px;

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: center;
        color: #282828;
    }
}

.aboutus {
    display: flex;
    width: 1240px;
    top: 436px;
    left: 99px;
    gap: 107px;
}

.imageAboutUs {
    width: 50%;
}

.textAboutUs {
    width: 50%;
    padding-inline: 20px;
    padding-block: 50px;

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: left;
        color: #282828;
        margin-bottom: 20px;
    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: #606060;
    }
}

.ourVision {
    width: 1279px;
    gap: 129px;
    margin-top: 80px;
    display: flex;
    margin-bottom: 50px;

}

.ourVisionText {
    width: 50%;
    padding-block: 50px;

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        margin-bottom: 20px;
    }

    p {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;
        text-align: left;
        color: #606060;
    }

}

.ourVisionImage {
    width: 50%;
}

.joinCommunity {
    background: url("../../assets/png/Rectangle.png");
    width: 1261px;
    height: 564px;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;

}

.joinCommunityContent {
    width: 573px;
    gap: 16px;
    text-align: center;
    justify-content: center;

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px;

    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 1100px) {
    .companyHeroText {
        width: 100%;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;

        h1 {
            font-family: Poppins;
            font-size: 28px;
            font-weight: 600;
            line-height: 33.6px;
            text-align: center;
        }
    }

    .aboutus {
        margin: auto;
        display: block;
        width: 100%;
        height: 713px;
        gap: 32px;
    }

    .imageAboutUs {
        width: 100%;
    }

    .textAboutUs {
        width: 100%;
        margin-top: 30px;
        padding-inline: 0px;
        padding-block: 0px;

        h1 {
            font-family: Poppins;
            font-size: 28px;
            font-weight: 600;
            line-height: 33.6px;
            text-align: left;
            color: #282828;
            margin-bottom: 20px;
        }

        p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
        }
    }

    .ourVision {
        width: 100%;
        height: 478px;
        gap: 19px;
        margin-top: 80px;
        display: flex;
        flex-direction: column-reverse;

    }

    .ourVisionText {
        width: 100%;
        margin-top: 100px;
        padding-block: 0px;

        h1 {
            font-family: Poppins;
            font-size: 28px;
            font-weight: 600;
            line-height: 33.6px;
            margin-bottom: 10px;
            text-align: left;
        }

        p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
            color: #606060;
        }
    }

    .ourVisionImage {
        width: 100%;
        height: 204px;
        order: 1;
    }

    .joinCommunity {
        background-size: cover;
        width: 100%;
        height: 473px;
        }

    .joinCommunityContent {
        width: 304px;
        height: 329px;
        
        h1 {
            font-size: 28px;
            font-weight: 600;
            line-height: 33.6px;
            color: #FFFFFF;
            margin-bottom: 5px;
            text-align: center;
            margin-left: 10px;

        }

        p {
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 10px;
        }
    }
}