.auth {
  .divider {
    text-align: center;
    margin: 25px auto;
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 330px;

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 27%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #6c6c6c;
    }
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 27%;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #6c6c6c;
    }
  }
}
