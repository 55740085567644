:root {
  // --heading-black: #222222;
  // --primary-text: #626262;
  // --secondary-text: #515151;
  // --auth-text: #b1b1b1;
  // --primary-green: #2f8132;
  // --secondary-green: #e3f9e5;
  // --light-green: #f2fff3;
  // --warning-bg: #fffcf5;
  // --warning-text: #f79009;
  // --error-bg: #fef3f2;
  // --error-text: #d92d20;
  // --light-grey: #e1e1e1;
  // --drag-drop-bg: #4fc84f;
  // --off-white: #f7f9fb;
  // --primary-btn-disabled: #8cc7f7;
  // --primary-btn-hover: #0072cf;
  // --secondary-btn-hover: #0084e21a;
  // --input-border-color: #b1b1b1;
  // --footer-black: #34373a;
  // --footer-hr: #a5a5a5;
  // --secondary-50: #f4f9fd;
  // --gold: #ffd700;
  // --success: #00a788;

  --black: #000;
  --white: #fff;

  --primary-50: #e6e8f3;
  --primary-100: #b0b9da;
  --primary-200: #8a97c9;
  --primary-300: #5467b0;
  --primary-400: #3349a1;
  --primary-500: #001c89;
  --primary-600: #00197d;
  --primary-700: #001461;
  --primary-800: #000f4b;
  --primary-900: #000c3a;

  --grey-25: #fcfcfd;
  --grey-50: #f7f7f7;
  --grey-100: #e1e1e1;
  --grey-200: #cfcfcf;
  --grey-300: #b1b1b1;
  --grey-400: #9e9e9e;
  --grey-500: #7e7e7e;
  --grey-600: #626262;
  --grey-700: #515151;
  --grey-800: #3b3b3b;
  --grey-900: #222222;

  --error-25: #fffbfa;
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-200: #fecdca;
  --error-300: #fda29b;
  --error-400: #f97066;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;
  --error-800: #912018;
  --error-900: #7a271a;

  --warning-25: #fffcf5;
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;
  --warning-800: #93370d;
  --warning-900: #7a2e0e;

  --success-25: #f6fef9;
  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-200: #a6f4c5;
  --success-300: #6ce9a6;
  --success-400: #32d583;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;

  --info-25: #f2f9ff;
  --info-50: #e3f0ff;
  --info-100: #a3d0ff;
  --info-200: #7bbeff;
  --info-300: #4fa3ff;
  --info-400: #5791ff;
  --info-500: #3f6eff;
  --info-600: #2f5cff;
  --info-700: #2049ff;
  --info-800: #0e36ff;
  --info-900: #0528ff;
}
