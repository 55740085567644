// ????? ===================== REUSABLE ACCORDION COMPONENT =================== ?????? //
// ????? ===================== REUSABLE ACCORDION COMPONENT =================== ?????? //
.accordion {
    box-shadow: 0px 2px 0px rgba(128, 128, 128, 0.219);
}

.accordionItem {
    border-bottom: 1px solid transparent;
}

.accordionButton {
    width: 100%;
    background-color: var(--white);
    border: none;
    border-bottom: 1px solid var(--grey-300);
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 10px;
    color: var(--grey-800);
    p:nth-child(1) {
        max-width: 500px;
        margin: 0 !important;
    }
    p {
        margin: 0 !important;
    }
}

.accordionIcon {
    font-size: 24px !important;
    font-weight: 200;
}

.accordionButton:hover {
    background-color: var(--primary-50);
}

.accordionContent {
    display: block;
    padding: 20px;
    background-color: var(--grey-25);
    border-bottom: 1px solid #ccc;
}

.accordionContent:last-child {
    border-bottom: 1px solid transparent;
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 700px) {
    .accordionButton {
        p:nth-child(1) {
            max-width: 88%;
        }
    }
}
