@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./assets/css/variables.scss";
@import "./assets/css/app.scss";
@import "./assets/css/auth/auth.scss";

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: "Poppins", sans-serif;
}

html {
  // font-size: 62.5%;
  scroll-behavior: smooth;
  font-size: 14px;
}

body {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #ffffff !important;
  background-color: #f6f6f6 !important;
}

.comment-input {
  field-sizing: content;
}

.min-vh-100 {
  min-height: 100vh;
}

.container {
  max-width: 1300px !important;
  width: 95%;
  margin: 0 auto !important;
  // padding: 0 !important;
}

.chakra-modal__content-container {
  align-items: center !important;
  justify-content: center !important;
  padding: 0 10px;
  overflow: hidden !important;
}

// .h-/[48px/] input {
//   height: 100%;
// }

button {
  font-size: 14px;
  font-weight: 600 !important;
  border: none;
  outline: none;
  overflow: hidden;
  border-width: 2px;
  border-style: solid;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
}

button a {
  font-size: 14px;
  font-weight: 500 !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.primary-btn,
a.secondary-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  font-weight: 600;
}

//==== UI elements ====//

.custom-alert-box {
  display: grid;
  grid-template-columns: 0fr 16fr;
  gap: 10px;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  max-width: 1070px;
  .icon-size {
    width: 22px;
    height: 19px;
  }
  h2 {
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }
  p {
    line-height: 19px;
    font-weight: 400;
  }
}

.chakra-checkbox__control {
  background-color: var(--white) !important;
  border-width: 1.5px !important;
  border-color: var(--grey-300) !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}

.chakra-checkbox__control[aria-checked="true"],
.chakra-checkbox__control[data-checked] {
  background-color: var(--primary-50) !important;
  border-color: var(--primary-600) !important;
}

.chakra-checkbox__control:focus,
.chakra-checkbox__control:focus {
  background-color: var(--primary-50) !important;
  border-color: var(--primary-600) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1) !important;
}

.chakra-checkbox__control[aria-disabled="true"],
.chakra-checkbox__control[data-disabled] {
  background-color: var(--grey-100) !important;
  border-color: var(--grey-200) !important;

  .css-fsye1 {
    color: var(--grey-200);
  }
}

.dropzone {
  background-color: var(--primary-25) !important;

  h5 {
    color: var(--grey-900);
  }
}

//Primary input
.primary-input,
.primary-select {
  border: 1px solid #c7c7c7 !important;
  height: 100% !important;
  // padding: 10px 14px;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.emotion-css-cache-i2418r {
  padding-block: 0.7rem;
}

.primary-input:disabled,
.primary-select:disabled {
  background: var(--grey-50) !important;
  color: var(--grey-500) !important;
  border-color: var(--grey-300) !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: not-allowed;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
.primary-input:focus,
.primary-select:focus {
  border-color: var(--primary-600) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.css-lcw54a[aria-invalid="true"],
.css-lcw54a[data-invalid],
.css-1aoy5ht[aria-invalid="true"],
.css-1aoy5ht[data-invalid],
.primary-input[aria-invalid="true"],
.primary-input[data-invalid],
.primary-select[aria-invalid="true"],
.primary-select[data-invalid],
.PhoneInput .PhoneInputInput[aria-invalid="true"],
.PhoneInput .PhoneInputInput[data-invalid],
.PhoneInput .PhoneInputInput:invalid {
  border-color: var(--error-300) !important;
}

.css-lcw54a[aria-invalid="true"]:focus,
.css-lcw54a[data-invalid]:focus,
.css-1aoy5ht[aria-invalid="true"]:focus,
.css-1aoy5ht[data-invalid]:focus,
.primary-input[aria-invalid="true"]:focus,
.primary-input[data-invalid]:focus,
.primary-select[aria-invalid="true"]:focus,
.primary-select[data-invalid]:focus {
  border-color: var(--error-300) !important;
  box-shadow: 0px 0px 0px 4px rgba(254, 228, 226, 1) !important;
}

.css-42b2qy {
  height: 100% !important;
}

// .primary-input:invalid {
//   border-color: var(--error-300) !important;
//   box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
// }

.primary-input-addon {
  height: 100% !important;
  background-color: transparent !important;
  border: 1px solid var(--grey-300) !important;
  color: var(--grey-500) !important;
  font-size: 15px !important;
  font-weight: 400 !important;

  select {
    border: none !important;
    width: 100%;
    height: 100%;
    background-position-x: 99%;
    background-position-y: 50%;
    padding: 10px 3px !important;
  }
}

.primary-input-component {
  position: absolute !important;
  top: 50% !important;
  display: inline-block;
  // left: 9px !important;
  // height: 100% !important;
  margin: 0 4px;
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;

  select {
    border: none !important;
    width: 60px;
    // height: 100%;
    background-color: transparent;
    background-position-x: 99%;
    background-position-y: 50%;
    padding: 8px 3px !important;
  }

  svg,
  img {
    height: auto;
    width: 24px;
  }
}

.input-error-icon {
  position: absolute !important;
  top: 50% !important;
  margin: 0 4px;
  transform: translateY(-50%) !important;

  svg,
  img {
    height: 15px;
    width: 15px;
  }
}

.password-input-icon {
  padding: 0 !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 9px;
}

.disabled-input {
  background: var(--grey-50) !important;
  color: var(--grey-500) !important;
  cursor: not-allowed;
}

.PhoneInput {
  border: none !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;

  .PhoneInputCountry {
    height: 100% !important;
    background-color: transparent !important;
    border: 1px solid #c7c7c7 !important;
    border-right: none !important;
    color: var(--grey-500) !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin: 0 !important;
    padding: 0 16px;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  select {
    border: none !important;
    width: 100%;
    height: 100%;
    background-position-x: 99%;
    background-position-y: 50%;
    padding: 10px 12px 10px 6px !important;
  }
  .PhoneInputInput {
    border: 1px solid #c7c7c7 !important;
    height: 100% !important;
    padding: 10px 14px;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
//Button sizes
.btn-lg {
  // width: auto;
  // height: 48px !important;
  height: unset !important;
  padding: 18px 20px !important;
}

.btn-lg:has(a) {
  padding: 0px 0px !important;

  a {
    padding: 18px 30px !important;
  }
}

.btn-md {
  // width: auto;
  // height: 40px !important;
  height: unset !important;
  padding: 12px 16px !important;
}

.btn-md:has(a) {
  padding: 0px 0px !important;

  a {
    padding: 12px 16px !important;
  }
}

.btn-sm {
  // width: auto;
  // height: 36px !important;
  height: unset !important;
  padding: 10px 14px !important;
}

.btn-sm:has(a) {
  padding: 0px 0px !important;

  a {
    padding: 10px 14px !important;
  }
}

.btn-xs {
  width: auto;
  // height: 30px !important;
  height: unset !important;
  padding: 5px 14px !important;
}

.btn-xs:has(a) {
  padding: 0px 0px !important;

  a {
    padding: 5px 14px !important;
  }
}

//Primary button
.primary-btn {
  background: var(--primary-500) !important;
  color: var(--white) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-500) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.btn-outline-primary {
  background: transparent !important;
  border: var(--primary-500) 2px solid !important;
  color: var(--primary-500) !important;
}

.primary-btn:hover:not(.rounded-pill) {
  background: var(--primary-600) !important;
  border-color: var(--primary-600) !important;
  color: var(--white) !important;
}

.primary-btn:focus:not(.rounded-pill) {
  background: var(--primary-400) !important;
  border-color: var(--primary-400) !important;
  color: var(--white) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.primary-btn:disabled {
  background: #c7c7c7 !important;
  color: #6c6c6c !important;
  border-color: #c7c7c7 !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: default !important;
  // opacity: 0.4 !important;
}

.primary-btn:disabled:hover {
  background: #c7c7c7 !important;
  color: #6c6c6c !important;
  border-color: #c7c7c7 !important;
}

.primary-btn a {
  color: var(--white) !important;
  text-decoration: none !important;
}

//Secondary button
.secondary-btn {
  background: transparent !important;
  color: var(--primary-500) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-500) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.secondary-btn:hover {
  background: #e7effb !important;
  border-color: var(--primary-500) !important;
  color: var(--primary-500) !important;
}

.secondary-btn:focus:not(.rounded-pill) {
  background: var(--white) !important;
  border-color: var(--primary-500) !important;
  color: var(--primary-500) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.secondary-btn:disabled {
  background: #c7c7c7 !important;
  color: #6c6c6c !important;
  border-color: #c7c7c7 !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: default !important;
}
.secondary-btn:disabled:hover {
  background: #c7c7c7 !important;
  color: #6c6c6c !important;
  border-color: #c7c7c7 !important;
}

.secondary-btn a {
  color: var(--primary-500) !important;
  text-decoration: none !important;
}

//Primary error button
.primary-error-btn {
  background: var(--error-600) !important;
  color: var(--white) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-width: 1px;
  border-style: solid;
  border-color: var(--error-600) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.primary-error-btn:hover {
  background: var(--warning-700) !important;
  border-color: var(--warning-700) !important;
  color: var(--white) !important;
}

.primary-error-btn:focus {
  background: var(--error-600) !important;
  border-color: var(--error-600) !important;
  color: var(--white) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.primary-error-btn:disabled {
  background: var(--error-200) !important;
  border-color: var(--error-200) !important;
  color: var(--white) !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: default;
}
.primary-error-btn:disabled:hover {
  background: var(--error-200) !important;
  border-color: var(--error-200) !important;
  color: var(--white) !important;
  cursor: default;
}

.primary-error-btn a {
  color: var(--white) !important;
  text-decoration: none !important;
}

.list-style-check {
  list-style: url("./assets/svg/check-mark-blue-ul-li.svg");
  transform: translateX(8%);
  padding-right: 8%;
}
header .custom-nav-active-header-class.active {
  border-bottom: 1px solid var(--primary-500);
}

@keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgb(179 192 244);
  }
  100% {
    background-color: transparent;
  }
}

.flash-animation {
  animation: flash 0.2s linear infinite;
}

.react-dropdown-select {
  background: white !important;
  border-radius: 4px !important;
}

.react-dropdown-select-dropdown-position-bottom {
  top: 36px !important;
}
// .react-dropdown-select-content {
//   width: 100%;
//   left: 0;
//   /* height: 100%; */
//   right: 0;
//   /* top: 0; */
//   /* bottom: 0; */
//   border: 0;
//   span {
//     position: absolute;
//     left: 14px;
//     top: 50%;
//     transform: translateY(-50%);
//   }
// }
// .react-dropdown-select-input {
//   margin-left: 0 !important;
// }

// .react-dropdown-select-dropdown-handle {
//   right: 0;
//   position: absolute;
// }

// .react-dropdown-select-content {
//   flex-direction: column-reverse;
// }
