.searchComponent {
    margin-bottom: 60px;
    h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
    }

    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
    }
}

.searchArea {
    margin-top: 30px;
    // width: 70%;
    // height: 59px;
    border-radius: 8px;
    // padding-left: 8px;
    background-color: #f0f0f0;
    border: 1px solid #c2c2c2;
    display: flex;
    justify-content: space-between;

    // button {
    //   // width: 20%;
    //   height: 100%;
    //   background-color: #001c89;
    //   color: #ffffff;
    //   border-radius: 8px;
    // }
}

.inputDiv1 {
    // width: 100%;
    // height: 100%;
    // padding-inline: 5px;
    // padding-block: 5px;
    // padding-left: 10px;
    justify-content: center;

    input {
        width: 100%;
        height: 100%;
        // background-color: #f0f0f0;
        // border-right: 1px solid #dadada;
    }
}

.inputDiv2 {
    // width: 40%;
    // height: 100%;
    // padding-inline: 5px;
    // padding-block: 5px;
    // padding-left: 10px;
    justify-content: center;

    input {
        width: 100%;
        height: 100%;
        // background-color: #ffffff;
    }
}

.chickenRepublic {
    width: 100%;
    // max-width: 700px;
    margin-top: 30px;
    border: 1px solid #c2c2c2;
    border-radius: 20px;
    display: flex;
    padding: 8px;
}

.searchDetails {
    padding-block: 10px;
    padding-inline: 15px;
    width: 80%;

    span {
        // background-color: #dadada;
        // border-radius: 4px;
        // gap: 10px;
        // height: 34px;
        // border: 3px solid red;
        // padding: 5px;
        // margin-left: 5px;
    }

    h3 {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        text-align: left;
    }

    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
    }
}

.imageCR {
    // border: 4px solid red;
    //   width: 20%;
}

.textEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    cursor: pointer;
}

///BUSINESS PAGE STYLE??//

.backgroundImages {
    // background: linear-gradient(
    //   180deg,
    //   rgba(57, 57, 57, 0) 0%,
    //   #070707 95.31%
    // ) !important;
    // background-image: "https://devtradetraka.blob.core.windows.net/business-logos/c053603041721301551.png";
    // background-size: contain;
    width: 100%;
    height: 480px;
    // border: 3px solid #001C89;
}

// background: linear-gradient(180deg, rgba(57, 57, 57, 0) 0%, #070707 95.31%);

.buttonsBusiness {
    // border: 3px solid red;
    // margin-top: 20px;
    gap: 22px;
    // height: 48px;
    display: flex;

    p {
        color: #001c89;
    }
}

.buttonBlue {
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #001c89;
    background-color: #001c89;
    color: #ffffff;
}

.buttonWhite {
    display: flex;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #001c89;
    background-color: #ffffff;
    margin-left: 20px;
}

.overviewTab {
    display: flex;
    width: 100%;
}

.nameAndDetails {
    gap: 24px;
    // margin-bottom: 30px;

    h1 {
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: left;
        color: #ffffff;
    }
}

.ratingsBCK {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #ffffff;
    }
}

.verificationBCK {
    display: flex;
    margin-top: 14px;
    gap: 15px;
}

.bckWords {
    display: flex;
    // align-items: end;
    // border: 2px solid red;
    // margin-inline: 83px;
    gap: 16px;
    justify-content: space-between;
    position: relative;
    align-items: end;
    // top: 300px;
}

.allPhotos {
    // border: 5px solid black;
    margin-top: 120px;

    button {
        display: flex;
        width: 246px;
        height: 59px;
        padding: 16px 32px 16px 32px;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #001c89;
        background: #f5f5f5;
        color: #001c89;
    }
}

.verificationBCK p:nth-of-type(1) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #5467b0;
}

.verificationBCK p:nth-of-type(2) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
}

.dotImageBCK {
    width: 6px;
    height: 6px;
    align-items: center;
    display: flex;
    margin-top: 8px;
    color: #ffffff;
}

.businessTimeBCK {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 16px;

    button {
        width: 109px;
        height: 37px;
        padding: 8px 16px 8px 16px;
        gap: 4px;
        border-radius: 8px;
        background-color: #ffffff;
        border: none;
        color: #001c89;
    }
}

.businessTimeBCK p:nth-of-type(1) {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
}

.businessTimeBCK p:nth-of-type(2) {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
}

.businessdetails {
    gap: 24px;
    margin-bottom: 30px;

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: left;
    }
}

.firstTab {
    // border: 2px solid #001C89;
    max-width: 790px;
}

.secondTab {
    padding-top: 20px;
    height: 464px;
    width: 412px;
    border-radius: 8px;
    padding-inline: 20px;
    border: 1px solid #dadada;
    margin-left: 30px;

    h3 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #919191;
    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }

    span {
        color: #001c89;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }
}

.ratings {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }
}

.verification {
    display: flex;
    margin-top: 20px;
    gap: 15px;
}

.verification p:nth-of-type(1) {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #001c89;
}

.verification p:nth-of-type(2) {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.blue {
    color: #001c89;
}

.dotImage {
    width: 6px;
    height: 6px;
    align-items: center;
    display: flex;
    margin-top: 8px;
}

.businessTime {
    margin-top: 20px;
    display: flex;
    gap: 16px;
}

.businessTime p:nth-of-type(1) {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #001c89;
}

.businessTime p:nth-of-type(2) {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #282828;
}

.services {
    margin-top: 20px;
    height: 336px;
    border-bottom: 1px solid #c2c2c2;
    border-top: 1px solid #c2c2c2;

    h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
        margin-top: 25px;
    }
}

.location {
    display: flex;
    padding-top: 30px;
    padding-bottom: 60px;
    border-bottom: 1px solid #c2c2c2;
}

.hLocation {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
    }

    button {
        width: 172px;
        height: 27px;
        border: none;
        color: #001c89;
        display: flex;
    }

    p {
        color: #001c89;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
    }
}

.addressBox {
    width: 414.15px;

    img {
        margin-top: 10px;
    }
}

.address {
    display: flex;
    margin-top: 10px;

    p {
        width: 50%;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }

    button {
        width: 155.88px;
        height: 45.44px;
        padding: 10.72px 21.44px 10.72px 21.44px;
        gap: 7.15px;
        border-radius: 7.15px;
        border: 1px solid #001c89;
        background-color: #ffffff;
        margin-left: 50px;
        color: #001c89;
    }
}

.coloredText {
    margin-top: 10px;
    color: #001c89;

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 21.44px;
        text-align: left;
    }
}

.businessHours {
    margin-left: 20px;
    width: 100%;
    padding: 10px 10px 20px 30px;
    // border: 1px solid rebeccapurple;
}

.editButton {
    display: flex;
    border: none;
    justify-content: end;
    color: #001c89;

    p {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
    }
}

.days {
    margin-right: 20px;

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }
}

.time {
    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }
}

.openNow {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #001c89;
    margin-left: 15px;
}

.aboutBusiness {
    padding-top: 25px;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 30px;

    span {
        visibility: hidden;
    }

    h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
    }

    p {
        width: 684px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }

    button {
        width: 134px;
        height: 48px;
        padding: 12px 24px 12px 24px;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #001c89;
        justify-content: end;
        align-content: end;
    }
}

.community {
    border-bottom: 1px solid #c2c2c2;
    margin-top: 20px;
    padding-bottom: 50px;
}

.askCommunity {
    display: flex;
    margin-bottom: 14px;

    h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
    }

    button {
        border: none;
        width: 167px;
        height: 27px;
        gap: 4px;
        display: flex;
        color: #001c89;
        margin-left: 150px;
    }

    p {
        width: 431px;
        height: 24px;
        margin-top: 79px;
        gap: 24px;
    }

    span {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
    }
}

.communitydiv {
    padding-top: 25px;
}

.relatedItems {
    padding-bottom: 180px;
}

.pRelated {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    line-height: 43.2px;
    text-align: left;
}

.reviewTab {
    display: flex;
    margin-bottom: 100px;
    width: 100%;

    h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
    }
}

.totalAndAverage {
    display: flex;
    width: 743px;
    // height: 132px;
    padding: 16px 0px 16px 0px;
    gap: 32px;
    border: 1px solid #dadada;
}

.totalReviews {
    width: 223px;
    height: 100px;
    border-right: 1px solid #dadada;
    padding-left: 10px;

    h3 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
    }

    h2 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
    }

    p {
        color: #606060;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }
}

.averageRating {
    width: 223px;
    height: 100px;
    border-right: 1px solid #dadada;

    h3 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
    }

    h2 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
    }

    p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
    }
}

.progressBar {
    display: flex;

    p {
        font-family: HelveticaNeue;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        color: #919191;
    }
}

.phoneSortFilter {
    visibility: hidden;
}

.sortFilterButtons {
    margin-top: 30px;
    display: flex;
}

.sortByButton {
    width: 108px;
    height: 32px;
    padding: 4px 8px 4px 8px;
    border-radius: 8px;
    border: 1px solid #dadada;
    justify-content: space-between;
    display: flex;
    color: #6c6c6c;
}

.filterButton {
    margin-left: 20px;
    width: 169px;
    height: 32px;
    padding: 4px 8px 4px 8px;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #dadada;
    display: flex;
    color: #6c6c6c;
}

.customerSurvey {
    margin-top: 50px;
    padding: 20px;
    gap: 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
    // border: 3px solid #001C89;
}

.monthFormat {
    margin-left: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #c4c4c4;
}

.customerNameImage {
    // border: 2px solid rebeccapurple;

    img {
        width: 60px;
        height: 60px;
    }

    h1 {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        text-align: left;
    }

    h4 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #c4c4c4;
    }

    span {
        color: #c4c4c4;
    }
}

.honestReview {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #000000;
    margin-top: 20px;
}

.emojiLikes {
    display: flex;
    margin-block: 18px;
    gap: 16px;
    opacity: 0px;

    p {
        justify-content: end;
    }
}

.fillRating {
    // border: 2px solid red;
    display: flex;
    margin-top: 5px;
}

.verifiedReviews {
    margin-block: 50px;

    h1 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
        color: #001c89;
    }
}

.aVerifiedReview {
    margin-top: 50px;
    padding: 20px;
    gap: 16px;
    border-radius: 8px;
    // background-color: #F5F5F5;
    // border: 3px solid #001C89;
}

.optionButton {
    justify-content: space-around;
    // border: 2px solid red;
}

.justifyContent {
    justify-content: space-between;
    display: flex;
}

.overFlow {
    margin-top: 30px;
    max-width: 860px;
    border-top: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    display: flex;
    justify-content: space-between;
    padding-block: 30px;
    padding-left: 15px;
}

.errorDiv {
    // margin-top: 300px;
    max-width: 580px;
    padding: 20px;
    gap: 20px;
    border-radius: 16px;
    background-color: #ffffff;
    // border: 3px solid #001c89;

    h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        text-align: left;
        color: #606060;
    }
}

.closeButton {
    position: relative;
    display: flex;
    justify-content: end;
    margin-bottom: 20px;

    span {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #001c89;
    }
}

.displayPhone {
    display: none;
}

.webDetails {
    display: none;
}

.forMobile {
    display: none;
}
.mobileSearchArea {
    display: none;
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 1100px) {
    .overFlow {
        margin-top: 30px;
        width: 100%;
        border-top: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;
        display: flex;
        justify-content: space-between;
        padding-block: 30px;
        padding-left: 15px;
    }

    .chickenRepublic {
        width: 100%;
        margin-top: 30px;
        border: 1px solid #c2c2c2;
        border-radius: 20px;
        display: flex;
        // padding: 5px;
    }

    .bckWords {
        // margin-inline: 0px;
        // width: 100%;
        // height: 70%;
        // top: 200px;
    }

    .noneDisplay {
        display: none;
    }

    .displayPhone {
        display: flex;
        margin-right: 10px;
    }

    .searchDetails {
        img {
            display: none;
        }
    }

    .buttonsBusiness {
        // gap: 0px;

        p {
            display: none;
        }
    }

    .buttonBlue {
        // height: 37px;
        padding: 8px;
        border-radius: 8px;
    }

    .nameAndDetails {
        margin-top: 120px;
        // border: 2px solid rebeccapurple;
        // margin-bottom: 30px;

        h1 {
            font-size: 28px;
            line-height: 33px;
        }
    }

    .allPhotos {
        margin-top: 30px;
        margin-left: 174px;
        margin-right: 0px;
        // border: 2px solid yellow;

        button {
            width: 100%;
            height: 48px;
            padding: 12px 24px 12px 24px;
        }
    }

    .webDetails {
        display: block;
        padding-top: 20px;
        width: 100%;
        border-radius: 8px;
        padding-inline: 20px;
        border: 1px solid #dadada;
        margin-left: 0px;

        h3 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #919191;
        }

        p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
        }

        span {
            color: #001c89;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
        }
    }

    .reviewTab {
        width: 100%;
    }

    .firstTab {
        // border: 2px solid #001C89;
        width: 100%;
    }

    .secondTab {
        display: none;
    }

    .hLocation {
        display: block;

        button {
            margin-left: 0px;
        }
    }

    .location {
        width: 100%;
        display: block;
    }

    .addressBox {
        width: 100%;
    }

    .address {
        margin-top: 10px;
    }

    .aboutBusiness {
        width: 100%;

        span {
            margin-block: 10px;
            visibility: visible;
            // width: 172px;
            height: 27px;
            border: none;
            color: #001c89;
            display: flex;
        }

        p {
            width: 100%;
        }
    }

    .askCommunity {
        display: block;

        button {
            margin-top: 10px;
            margin-left: 0px;
        }
    }

    .editButton {
        visibility: hidden;
    }

    .totalAndAverage {
        display: block;
        width: 100%;
        border: 1px solid #dadada;
        padding-left: 20px;
        margin-bottom: 20px;
    }

    .totalReviews {
        border-right: none;
        width: 100%;
        // border: 2px solid #001C89;
    }

    .averageRating {
        width: 100%;
        border-right: none;
        // border:  3px solid red;
    }

    .progressBar {
        // border: 2px solid yellow;
        padding-left: 20px;
    }

    .pcDisplay {
        display: none;
    }

    .phoneSortFilter {
        visibility: visible;
        margin-top: 15px;
        // border: 2px solid red;
        width: 100%;

        h1 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #001c89;
        }
    }

    .sortFilterButtons {
        // visibility: hidden;
        border: 2px solid brown;
        width: 100%;
        display: block;
        display: none;
    }

    .phoneFilter {
        border: 1px solid #dadada;
        padding: 4px 8px 4px 8px;
        gap: 4px;
        border-radius: 8px;
    }
    .verifiedReviews {
        display: none;
    }
    .phoneExample {
        display: none;
    }
    .forMobile {
        display: flex;
    }
    .forPC {
        display: none;
    }

    .businessHours {
        margin-left: 0px;
        width: 100%;
        padding: 10px 10px 20px 10px;
        // border: 1px solid rebeccapurple;
    }

    .mobileSearchArea {
        display: none;
        width: 100%;
        border: 1px solid #dadada;
        border-radius: 8px;
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        margin-top: 20px;
        background-color: #ffffff;

        input {
            width: 100%;
            padding: 10px;
        }
    }
    .firstInput {
        width: 80%;
        // border: 2px solid rebeccapurple;
        // border-bottom: 1px solid #dadada;
    }
    // .secondInput {
    //   width: 80%;
    //   // border: 2px solid yellow;
    // }
}

@media (max-width: 550px) {
    .verificationBCK {
        margin-top: 8px;
    }
    .bckWords {
        // margin-inline: 0px;
        // width: 100%;
        // height: 70%;
        // top: 150px;
        flex-direction: column-reverse;
        align-items: start;
        // gap: 0px;
        // border: 3px solid red;
    }
}

@media (min-width: 750px) {
    .ellipsis {
        white-space: nowrap; /* Prevents text from wrapping to a new line */
        overflow: hidden; /* Hides the overflow text */
        text-overflow: ellipsis; /* Adds the ellipsis (...) */
    }
}
