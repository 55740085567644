@import "../../assets/css/variables.scss";

.dashboard {
  background-color: #f5f5f5;
  width: 100%;
  // min-width: 100vw;
}

.navbar {
  width: 100%;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 9999;
}

.nav_bar_cont {
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  gap: 20px;
  overflow: hidden;
}

.navbar_logo img {
  height: 40px;
}

.navbar_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.navbar_links a {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
}

.navbar_links a:hover,
.navbar_links a:active {
  color: #001c89;
  // font-size: 16px;
}

.navbar_button {
  background-color: none;
  color: #001c89;
  padding: 10px 20px;
  border: 1px solid #001c89;
  border-radius: 8px;
  cursor: pointer;
}

//main-content

.main_content {
  margin: 0 auto;
}

.business_header {
  margin-bottom: 20px;
  h1 {
    font-size: 48px;
    color: #1f1f1f;
  }
  p {
    font-size: 14px;
    color: #001c89;
    padding-left: 2px;
  }
}

.business_actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.business_actions a {
  flex: 1;
  margin: 0 5px;
  padding: 2% 4%;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  height: 150px;

  img {
    margin: 10px auto;
    width: 30%;
  }
}

.business_actions .feature_btn :hover {
  color: #001c89;
}

.average_ratings {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.profile_interactions,
.performance_analytics {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.profile_interactions {
  .interaction_cards {
    height: 80px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 10px;
  }
}

.performance_analytics {
  height: 150px;
}

.ratings_details {
  padding: 20px 2%;
}

.rating_bar {
  height: 5px;
  background-color: #dadada;
  width: 200px;
}

.yellow_bar {
  height: 5px;
  background-color: #ffa800;
}

//Read-review

.read_review {
  position: relative;
  // width: 1000px;
}

.review_cont {
  border-radius: 16px;
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
    color: #001c89;
  }

  .filter {
    border: solid 1px #dadada;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #6c6c6c;
    font-size: 14px;
    min-height: 35px;

    // .ratingStars {
    //   display: none;
    // }
    // &:hover {
    //   .ratingStars {
    //     display: flex;
    //   }
    // }
  }
}

.review_card {
  padding: 20px 16px;
  border-radius: 16px;
  p {
    color: #606060;
    font-size: 14px;
  }
  &:hover {
    .rating_group {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
}

.rating_group {
  background-color: #f5f5f5;
  padding: 2%;
  border-radius: 16px;
  text-align: center;
  width: 234px;
  color: #ffa800;
  // transform: scale(0);
  // opacity: 0;
  // visibility: hidden;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  p {
    font-size: 10px;
    color: #606060;
  }
}

.flex_group {
  margin-bottom: 10px;
}
.review_input {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 16px;
  padding: 3px 2% 3px 0;
  margin-top: 15px;

  input {
    background-color: #f5f5f5;
    outline: none;
  }
}

///Business info

.back_btn {
  margin-bottom: 40px;
}
.page_container {
  position: relative;
}

.sidebar {
  width: 25%;
}

.sidebar_cont {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sidebar_item {
  padding: 0.5rem 0;
  cursor: pointer;
}

.sidebar_item:hover {
  color: #001c89;
}

.sidebar_item_active {
  color: #001c89;
  font-weight: bold;
}

.content {
  width: 60%;

  .page_title h2 {
    color: #001c89;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 500;
  }
}

.section_title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.info_section,
.services_section,
.location_section,
.hours_section {
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 3% 4%;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.info_section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.info_section_header h2 {
  font-size: 1.25rem;
  font-weight: bold;
}

.info_section_header button {
  height: 40px;
  border: 1px solid #001c89;
  padding: 3px 6px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  color: #001c89;
  border-radius: 8px;
}

// .info_section_header button:hover {
//   text-decoration: underline;
// }

.info_list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info_list_item {
  display: flex;
  justify-content: space-between;
}

.info_list_item span {
  color: #666;
}

.business_d_cont {
  color: #666;
}

.service_tag {
  background-color: #e5e7eb;
  color: #333;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.location_section img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hours_list {
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.25rem;
}

.hours_list_item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

//add photo
.photos_section {
  // width: 1000px;
  height: 100%;
  margin: 0 auto;
}

.photos_section_cont {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  height: auto;
}

.section_title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.photos_container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
}

.no_photos_text {
  color: #999999;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.add_photos_button {
  background-color: #001c89;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.add_photos_button:hover {
  background-color: #0026a1;
}

.add_photos_icon {
  margin-right: 10px;
}

//questions
.questions_page {
  width: 1000px;
  margin: 0 auto;
}

.question_title {
  font-size: 24px;
  font-weight: bold;
}

.question_subtitle {
  font-size: 16px;
  color: #888;
  margin-bottom: 20px;
}

.questions_container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 0 auto;
}

.no_questions {
  text-align: center;
  color: #888;
}

.question_card {
  border: 1px solid #eaeaea;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.question_header {
  margin-bottom: 10px;
}

.question_text {
  font-weight: bold;
}

.question_author {
  color: #888;
  font-size: 14px;
}

.answer_section {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #eaeaea;
}

.answer_text {
  font-weight: bold;
}

.answer_author {
  color: #888;
  font-size: 14px;
  margin-top: 5px;
}

.no_fanswer {
  margin-top: 10px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
  text-align: center;
  color: #888;
}

.questions_action_buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.answer_button,
.mark_accurate_button {
  background: #001c89;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.mark_accurate_button {
  background: none;
  color: #001c89;
}

//account-setting

.account_settings_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  background-color: #f6f6f6;
  padding: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.settings_list {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.settings_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  font-size: 16px;
}

.settings_item:last-child {
  border-bottom: none;
}

.settings_item.logout {
  color: red;
}

.arrow {
  font-size: 18px;
}

///Footer

.footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  padding: 10px 20px;
  background-color: white;
  text-align: right;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 600px) {
  .business_actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 0;
  }
}
