///!! ============== FEATURES IMAGES COMPONENT ?? //

.imageContainer {
    background: url("../../assets/svg/hero-img-1.webp");
    background-size: cover;
    border-radius: 10px;
    height: 853px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bgdtext {
    position: relative;
    text-align: center;
    max-width: 750px;
    width: 90%;

    h2 {
        max-width: 522px;
        color: #ffffff;
        font-family: Poppins;
        font-size: 60px;
        font-weight: 500;
        line-height: 72px;
        text-align: center;
        margin: auto;
        margin-bottom: 30px;
    }

    p {
        width: 600px;
        color: #ffffff;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 300;
        line-height: 27px;
        text-align: center;
        margin: auto;
        margin-bottom: 20px;
    }
}

.search {
    margin-top: 30px;
    width: 814px;
    height: 59px;
    border-radius: 8px;
    padding-left: 8px;
    background-color: #ffffff;
    display: flex;

    button {
        width: 20%;
        height: 100%;
        background-color: #001c89;
        color: #ffffff;
        border-radius: 8px;
    }
}

.inputDiv input:nth-child(1) {
    border-right: 1px solid #dadada;
    width: 100%;
}

.inputDiv {
    width: 40%;
    height: 100%;
    padding-inline: 5px;
    padding-block: 5px;
    padding-left: 10px;
    justify-content: center;

    input {
        width: 80%;
        height: 100%;
        background-color: #ffffff;
        padding-left: 10px;
    }
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 1100px) {
    .imageContainer {
        width: 100%;
        height: 400px;
        background-size: cover;
    }

    .bgdtext {
        position: relative;
        text-align: center;
        max-width: 800px;
        width: 90%;

        h2 {
            // width: 332px;
            font-size: 32px;
            line-height: 38.4px;
            text-align: center;
        }

        p {
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            text-align: center;
            max-width: 270px;
        }
    }

    .search {
        margin-top: 30px;
        width: 95%;
        height: 48px;
        margin: auto;

        span {
            display: none;
        }
    }
}

//!! ============== What WE DO COMPONENT ?? //
.featureArea {
    padding-block: 3rem;
    margin-top: 100px;
    // padding: 1%;
    // width: 1440px;
    // height: 490px;
    background-image: url(../../assets/svg/whatWeDo-BG.svg);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;

    .featureGroup {
        display: flex;
    }

    h2 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
    }
}

.featuredOne {
    width: 60%;
}

// .featuredTwo {
//   width: 40%;
//   margin-right: 150px;
// }

.header {
    // width: 169px;
    // height: 51px;
    display: inline-block;
    padding: 12px 25px 12px 25px;
    gap: 10px;
    border-radius: 8px;
    margin-bottom: 16px;

    background-color: #f5f5f5;

    h2 {
        color: #001c89;
    }
}

.headerBig {
    max-width: 645px;
    // height: 58px;
    gap: 0px;
    margin-bottom: 14px;

    h2 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: left;
        color: #282828;
    }
}

.featuredPart1 {
    width: 274px;
    // height: 205px;
    gap: 8px;
}

.featuredPart2 {
    width: 274px;
    // height: 205px;
    gap: 8px;
    margin-left: 20px;
}

.featuredPartHeader {
    max-width: 266px;
    // height: 42px;

    h2 {
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        text-align: left;
    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #606060;
    }
}

.features {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 580px;
    // height: 202px;
    gap: 32px;

    margin-bottom: 20px;
}

.featuredIcon {
    width: 48px;
    height: 48px;
    gap: 0px;
    border-radius: 8px;

    background-color: #e6e8f3;
    margin-bottom: 20px;
}

.bButton {
    margin-top: 40px;
}

.blueButton {
    width: 168px;
    height: 65px;
    padding: 16px 32px 16px 32px;
    border-radius: 8px;
    background-color: #001c89;
    color: #fff;
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 1100px) {
    .featureArea {
        // display: block;
        width: 100%;
        // height: 703px;
        padding-block: 0px;
        // background-image: none;
        margin: auto;
        margin-top: 50px;
    }

    .header {
        // width: 30%;
        // height: 42px;
        padding: 12px;
        border-radius: 8px;
        background-color: #f5f5f5;

        h2 {
            line-height: 14.4px;
            font-size: 12px;
        }
    }

    .headerBig {
        width: 324px;
        height: 36px;
        margin-top: 10px;
        margin-bottom: 10px;

        h2 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            text-align: left;
            color: #282828;
        }
    }

    .featuredPart1 {
        width: 50%;
        // height: 201px;
        margin-left: 5px;
    }

    .featuredPart2 {
        width: 50%;
        // height: 201px;
        margin-left: 5px;
    }

    .featuredPartHeader {
        h2 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: left;
            margin-bottom: 5px;
        }

        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 200;
            line-height: 21px;
            text-align: left;
            color: #606060;
        }
    }

    .features {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        margin: auto;
        width: 100%;
        // height: 202px;
        gap: 10px;
    }

    .featuredIcon {
        width: 32px;
        height: 32px;
        gap: 0px;
        border-radius: 5.33px;
        background-color: #e6e8f3;
        margin-bottom: 5px;
    }

    .bButton {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .blueButton {
        width: 140px;
        height: 48px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        background-color: #001c89;
        color: #fff;
        border: 2px solid #001c89;
    }

    .featuredOne {
        width: 100%;
    }

    .featuredTwo {
        width: 100%;
        margin-right: 0px;
        margin-top: 20px;
        // background-image: url(../../assets/png/womanWithBagsBG.png);
        // background-size: cover;
        // height: 305px;

        img {
            src: url(../../assets/png/WomanSmilingBags.png);
            // width: 300px;
            margin-left: 0px;
            // height: 330px;
            // margin-left: -21px;
        }
    }
}

//!! ============== REVIEW COMPONENT ?? //
.something {
    margin-bottom: 30px;
}

.reviews {
    //   width: 1280px;
    //   height: 1260px;
    margin-top: 50px;

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: left;
        // margin-bottom: 12px;
    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0;
        // margin-top: 10px;
    }
}

.tabHeader {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.cardsSingle {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px calc((100% - 94%) / 3);
}

.cardsSingle2 {
    display: flex;
    gap: 20px;
    margin-top: 100px;
}

.cardOne {
    width: 23.5%;
    //   height: 366px;
    border-radius: 8px;
    border: 0.5px solid #c2c2c2;
    overflow: hidden;
}

.cardHeader {
    display: flex;
}

.cardHeaderImage {
    width: 40px;
    height: 40px;

    h2 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: center;
        height: 100%;
        width: 100%;
        background-color: #001c89;
        color: #ffffff;
        padding-inline: 5px;
        padding-block: 5px;
    }
}

.cardHeaderName {
    margin-left: 10px;

    h3 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #282828;
    }

    p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #606060;
    }
}

.cardBodyImage {
    margin-top: 0px;
    height: 73px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;

    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #1f1f1f6b;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.companyAndRating {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 40px;
    align-items: center;
}

.companyName {
    width: 70%;

    h1 {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #4d4d4d;
    }
}

.companyRating {
    width: 30%;
    display: inline-flex;
    padding: 6px;
    gap: 5px;
    align-items: center;

    img {
        width: 20px;
        height: 20px;
        justify-content: end;
    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #303030;
    }
}

.cardBodyText {
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
    }

    span {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        cursor: pointer;
        color: #001c89;
        // margin-top: 10px;
        display: inline-block;
    }
}

.reviewButton {
    gap: 30px;
}

.divider {
    color: #dadada;
}

.writeReviewButton {
    margin-top: 50px;
    margin: 50px auto 0;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 384px;
    // height: 59px;
    gap: 24px;
}

.tabList {
    border-bottom: 0.5px solid #c2c2c2;
}

.mobileView {
    display: none;
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 1100px) {
    .reviews {
        width: 100%;
        margin: auto;
        // height: 1900px;
        margin-top: 50px;
        margin-bottom: 40px;

        h1 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            text-align: center;
        }

        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    // .cardsSingle2 {
    //   display: none;
    // }

    // .cardsSingle {
    //   display: block;
    //   gap: 20px;
    //   margin-top: 20px;
    // }

    // .cardOne {
    //   width: 100%;
    //   // height: 376px;
    //   padding: 0px;
    //   gap: 16px;
    //   border-radius: 8px;
    //   margin-bottom: 30px;
    // }

    // .cardHeader {
    //   width: 253px;
    //   height: 44px;
    //   padding: 0px 64px 0px 0px;
    //   margin-top: 3px;
    // }

    .cardHeaderImage {
        width: 40px;
        height: 40px;
    }

    .cardHeaderName {
        // height: 44px;
        // width: 141px;

        h3 {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 15px;
            text-align: left;
        }

        p {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
        }
    }

    .cardBodyImage {
        img {
            width: 100%;
        }
    }

    .companyAndRating {
        display: flex;
        // height: 21px;
        margin-top: 10px;
        margin-bottom: 10px;
        gap: 5px;
    }

    .companyName {
        h1 {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            color: #4d4d4d;
            margin-bottom: 0;
        }
    }

    .companyRating {
        width: 30%;
        display: inline-flex;
        gap: 5px;

        img {
            width: 14.41px;
            height: 12.91px;
            justify-content: end;
            margin-top: -5px;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #303030;
            // margin-top: -10px;
        }
    }

    .cardBodyText {
        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
        }

        span {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #001c89;
        }
    }

    .writeReviewButton {
        margin: auto;
        margin-top: 30px;
        width: 100%;
        gap: 8px;
        display: flex;
    }

    .tabList {
        display: none;
    }

    .mobileView {
        margin: auto;
        width: 100%;
        display: flex;
        gap: 100px;
        // border: 3px solid red;
        justify-content: space-between;

        p {
            width: 50%;
            color: #001c89;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
        }

        button {
            width: 20%;
            margin: auto;
            display: flex;
            border: none;
            color: #001c89;
            align-items: right;
            justify-content: right;
        }
        span {
            // border: 2px solid gold;
            margin-bottom: 3px;
        }
    }
}

//!! ============== WHY TRADE TRACKA COMPONENT ?? //

.whyTradeTracka {
    margin-top: 140px;
    margin-bottom: 4rem;
    background-image: url(../../assets/png/second-background.png);
    background-size: cover;
}

.sizeFeature {
    display: flex;
}

.textPart {
    width: 590px;
    // height: 434px;
    margin-left: 100px;
    margin-top: 0px;

    h4 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
    }

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.textPartHeader {
    // width: 233px;
    // height: 51px;
    padding: 12px 25px 12px 25px;
    gap: 10px;
    border-radius: 8px;
    background-color: #f5f5f5;
    display: inline-block;

    h4 {
        color: #001c89;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: left;
    }
}

.imagePart {
    width: 522px;
    // height: 473px;
    gap: 0px;
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 1100px) {
    .whyTradeTracka {
        padding-block: 1rem;
        margin-top: 20px;
        width: 100%;
        // height: 750px;
        background-image: none;
    }

    .textPart {
        width: 100%;
        // height: 370px;
        padding: 1rem;
        margin: auto;
        order: 1;

        h1 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .textPartHeader {
        // width: 50%;
        // height: 42px;
        padding: 12px;
        gap: 10px;
        border-radius: 8px;
        background-color: #f5f5f5;
        display: inline-block;

        h4 {
            color: #001c89;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
            text-align: left;
        }

        h1 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .imagePart {
        margin-top: 20px;
        width: 100%;
        // height: 348px;
        background-image: url(../../assets/png/second-background.png);

        img {
            width: 84%;
            height: 100%;
        }
    }
}

//!! ============== REDEFINE COMPONENT ?? //

.redefine {
    // width: 1281px;
    padding: 113px 98px 113px 102px;
    border-radius: 20px;
    background-color: #001c89;
    display: flex;
    margin-top: 150px;
    margin-bottom: 100px;
}

.redefineText {
    width: 597px;
    top: 113px;
    left: 102px;
    gap: 0px;

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: left;
        color: #ffffff;
    }
}

.redefineLink {
    margin-left: 270px;
    margin-top: 50px;

    button {
        width: 191px;
        height: 59px;
        background-color: #ffffff;
        border-radius: 8px;
        border: 1px solid #001c89;
        padding: 16px 32px 16px 32px;
        color: #001c89;
    }
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 1100px) {
    .redefine {
        width: 100%;
        display: block;
        margin-top: 30px;
        margin-bottom: 50px;
        padding: 51px 18px 71.5px 38px;
    }

    .redefineText {
        width: 100%;

        h1 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            text-align: left;
            color: #ffffff;
        }
    }

    .redefineLink {
        margin-left: 0px;
        margin-top: 20px;
    }
}

//!! ============== GET NEW CUSTOMERS COMPONENT ?? //
.getNewCustomers {
    //   width: 1280px;
    //   height: 460px;
    margin-top: 60px;
    display: flex;
}

// .gNCtext {
//   width: 35%;
// }

.gNCScroll {
    width: 65%;
}

.divGNCText {
    margin-top: 50px;
    // width: 279px;
    // height: 204px;
    gap: 16px;

    h1 {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 500;
        line-height: 57.6px;
        text-align: left;
    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        // width: 260px;
        // height: 72px;
    }
}

.reviewCard {
    margin-top: 50px;
    width: 958px;
    // height: 400px;
    display: flex;
}

.cards {
    width: 334px;
    margin-right: 30px;
    // height: 349px;

    img {
        width: 334px;
        // height: 314px;
        display: block;
        border-radius: 8px;
    }
}

.nameAndRatings {
    display: flex;
    // width: 335px;
    height: 27px;
    padding: 0px 0.77px 0px 0px;
    margin-top: 5px;

    img {
        width: 16.41px;
        height: 15.59px;
    }
}

.name {
    width: 80%;
    text-align: left;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
}

.rating {
    width: 20%;
    justify-content: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;

    img {
        width: 20px;
        height: 20px;
    }

    p {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin-left: 10px;
    }
}

.commentsNo {
    display: flex;
    position: relative;
    top: -80px;
    left: 16px;
    color: #ffffff;
    height: 24px;
    width: 62px;
    font-size: 15px;
}

// ??? !!!!! ??????  MEDIA QUERIES FOR TABLETS AND LESS !!!!! ??????? //
@media only screen and (max-width: 1100px) {
    // .getNewCustomers {
    //   width: 100%;
    //   // height: 492px;
    //   margin-top: 120px;
    //   display: block;
    //   border-top: 1px solid #c2c2c2;
    //   border-bottom: 1px solid #c2c2c2;
    // }

    .gNCtext {
        margin-top: 5px;
        margin-left: 5px;
        width: 279px;
        // height: 148px;
        gap: 16px;

        h1 {
            font-size: 28px;
            line-height: 33.6px;
        }

        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            text-align: left;
        }

        button {
            margin-top: 0px;
            position: relative;
            // top: -60px;
        }
    }

    .gNCScroll {
        width: 100%;
        // height: 280px;
    }

    .divGNCText {
        margin-top: 0px;
        // width: 279px;
        max-width: 250px;
        // height: 148px;
    }

    .cards {
        width: 267.97px;
        height: 280px;
        margin-right: 0px;

        img {
            width: 267.97px;
            // height: 251.92px;
            display: block;
            border-radius: 6.42px;
            gap: 6.42px;
        }
    }

    .nameAndRatings {
        display: flex;
        margin-top: 2px;
        width: 267.77px;
        height: 21.66px;
        gap: 0px;

        img {
            width: 14px;
            height: 14px;
        }
    }

    .name {
        font-family: Poppins;
        font-size: 14.44px;
        font-weight: 600;
        line-height: 21.66px;
        text-align: left;
    }

    .rating {
        p {
            font-family: Poppins;
            font-size: 12.84px;
            font-weight: 600;
            line-height: 19.26px;
            text-align: left;
            margin-left: 0px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .getNewCustomers {
        width: 100%;
        // height: 492px;
        // margin-top: 120px;
        display: block;
        border-top: 1px solid #c2c2c2;
        border-bottom: 1px solid #c2c2c2;
    }

    .cardsSingle {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 20px calc(100% - 96%);

        .cardOne {
            width: 48%;
            border-radius: 8px;
        }
    }

    .featureArea {
        background-size: initial;
        background-position: right bottom;
        .featureGroup {
            flex-direction: column;
            .featuredTwo {
                img {
                    margin-left: -18px;
                }
            }
        }
    }

    .sizeFeature {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        // height: 750px;
        margin: auto;
    }
}

// @media only screen and (max-width: 700px) {
//   .featureArea {
//     background-size: initial;
//     background-position: right bottom;
//     .featureGroup {
//       flex-direction: column;
//       .featuredTwo {
//         img {
//           margin-left: -18px;
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 500px) {
    .cardsSingle {
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 20px 0;

        .cardOne {
            width: 100%;
            border-radius: 8px;
        }
    }
}
